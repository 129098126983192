import React, {useEffect} from "react";
import "./App.css";
import { 
  Main, 
  Roles,
  ResumeAssessment, 
  QAHelper, 
  ATSIntegration, 
  Billing, 
  GetStarted, 
  Pricing,
  Login,
  Signup,
  ResumeBuilder,
  HonestResume
 } from './pages';
import LoginModal from "./components/LoginModal";
import LoadingScreen from "./components/LoadingScreen";
import ContactUsModal from "./components/ContactUsModal";
import { firebaseAuth } from "./services/firebase";
import { getSkills } from "./services";
import { useAtom } from 'jotai';
import { userAtom, skillAtom } from "./atoms";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

var mobile = require('is-mobile');

const ROUTES = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/roles",
    element: <Roles />
  },
  {
    path: "/resume-assessment",
    element: <ResumeAssessment />
  },
  {
    path: "/honest-resume",
    element: <HonestResume />
  },
  {
    path: "tools/qa-helper",
    element: <QAHelper />
  },
  {
    path: "/tools/resume-builder",
    element: <ResumeBuilder />
  },
  {
    path: "ats",
    element: <ATSIntegration />
  },
  {
    path: "billing",
    element: <Billing />
  },
  {
    path: "get-started",
    element: <GetStarted />
  },
  {
    path: "pricing",
    element: <Pricing />
  },
  {
    path: "login",
    element: <Login />
  },
  {
    path: "signup",
    element: <Signup />
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

const MOBILE_ROUTES = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

const router = createBrowserRouter(mobile() ? MOBILE_ROUTES : ROUTES);

const App = () => {
  const [user, setUser] = useAtom(userAtom);
  const [, setSkills] = useAtom(skillAtom);

  useEffect(() => {
      let unsubscribe;
      const checkAuth = async () => {
        unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
            setUser(user);
        });
      } 

      checkAuth();
     
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
  }, [setUser]);

  useEffect(() => {
    const fetchSkills = async () => {
      const skills = await getSkills();
      if (skills?.skill_names) {
        setSkills(skills?.skill_names);
        console.log(skills?.skill_names);
      }
    }
    if (user) {
      fetchSkills();
    }
  }, [user, setSkills])

  return (
    <>
      <RouterProvider router={router} />
      <LoginModal/>
      <ContactUsModal/>
      <LoadingScreen/>
    </>
  );
};

export default App;
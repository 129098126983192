import React, { useState, useRef, useEffect } from "react";
import styled from 'styled-components';
import Spinner from "../components/Spinner";
import ParsedResume from "../components/ParsedResume";
import PageContainer from "../components/PageContainer";
import WhiteButton from "../components/WhiteButton";
import { getHonestResume, submitMatch, saveNameHr } from "../services";

const Container = styled.div`
  margin-top: 0rem;
  width: 100%;
  height: 100%;
  padding: 2.5rem;
  align-self: flex-start;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  .loading {
    /* font-weight: bold; */
    display:inline-block;
    /* font-family: monospace; */
    /* font-size: 30px; */
    clip-path: inset(0 0.7rem 0 0);
    animation: l 1s steps(3) infinite;
  }

  @keyframes l {
    to {
      clip-path: inset(0 -1ch 0 0)
    }
  }
  .matches-role {
    position: fixed;
    top: 1rem;
    right: 1rem;
    opacity: 1;
    transition: opacity 1s; 
    &.fade {
      opacity: 0;
    }
  }
  .content {
    flex: 1;
    position: relative;
    padding: 0 0.5rem;
    max-width: 60rem;
    .spinner {
      position: fixed;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      justify-content: center;
      display: flex;
      align-items: center;
    }
  }
`

const HonestResume = () => {
  const isLoading = useRef(false);
  const [ loading, setLoading ] = useState(true);
  const [data, setData]  = useState(null);
  const [resumeId, setResumeId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [saving, setSaving] = useState(false);
  const [showMatchesButton, setShowMatchesButton] = useState(true);

  useEffect(() => {
    const getResume = async () => {
      if (!isLoading.current) {
        isLoading.current = true;
        try {
          const url = new URL(window.location.href);
          const resume_id = url.searchParams.get("resume_id");
          const role_id = url.searchParams.get("role_id");
          setResumeId(resume_id);
          setRoleId(role_id);
          const data = await getHonestResume(resume_id);
          if (data?.yoe) {
            Object.getOwnPropertyNames(data?.yoe).forEach(key => {
              if (data?.yoe[key] === '0 years, 0 months') {
                data.yoe[key] = null;
              }
            })
          }
          setData(data);
        } catch (err) {

        } finally {
          isLoading.current = false;
          setLoading(false);
        }
      }
    }
    getResume();
  }, []);

  const saveName = async (name) => {
    await saveNameHr(resumeId, name);
  }

  const renderContent = () => {
    if (loading || !data) return (
      <div className="content">
        <div className="spinner">
          <Spinner/>
        </div>
      </div>
    );
    if (data) return (
      <div className="content">
          <ParsedResume data={data} saveNameHr={saveName}/>
      </div>
    )
  }

  const onMatchesRoleClick = async () => {
    try {
      setSaving(true);
      const res = await submitMatch(roleId, resumeId);
      console.log(res);
    } catch (err) {
      console.log(err);
    } finally {
      setSaving(false);
      setShowMatchesButton(false);
    }
  }

  const renderMatchesButton = () => {
    if (!showMatchesButton) return (
      <WhiteButton className="matches-role fade" onClick={()=>{}}>
        Saved!
      </WhiteButton>
    )
    if (saving) return (
      <WhiteButton className="matches-role" onClick={()=>{}}>
        <div className="loading">Saving...</div>
      </WhiteButton>
    )
    return <WhiteButton className="matches-role" onClick={onMatchesRoleClick}>Matches Role</WhiteButton>
  }


  return (
    <PageContainer 
      title={'Honest Resume'} 
      hideNavPanel={true} 
      hideNavBar={true} 
      hideLogo={true} 
      selected={null}>
    <Container>
      { renderContent() }
      {
        roleId &&
        resumeId &&
        renderMatchesButton()
      }
      
    </Container>
    </PageContainer>
  );
};

export default HonestResume;

import React, { useState } from "react";
import styled from 'styled-components';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import WhiteButton from "./WhiteButton";
import Spinner from "./Spinner";
import PercentLoader from "./PercentLoader";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as CheckmarkYes } from "../assets/images/checkmark-yes.svg";
import { ReactComponent as CheckmarkNo } from "../assets/images/checkmark-no.svg";
import { ReactComponent as MailIcon } from "../assets/images/mail.svg";
import { ReactComponent as GoogleIcon } from "../assets/images/google.svg";
import { ReactComponent as LinkedinIcon } from "../assets/images/linkedin.svg";
import { FaMinus } from "react-icons/fa6";
import { Tooltip } from 'react-tooltip';
import HR from "../assets/images/hr.png";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { getResumeAssessment } from "../services";


const PERCENT_LOADER_VIEW = 4;

const Container = styled.div`
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 0.5rem;
    border-radius: 0.2rem;
    overflow: hidden;
    font-family: Roboto;
    font-weight: 400;
    .banner {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      font-size: 1rem;
      min-height: 3.5rem;
      position: relative;
      .filter_cnt {
        /* position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); */
        font-size: 0.75rem;
        font-weight: 400;
        padding: 0.375rem !important;
        border-radius: 2px;
      }
      .label  {
        cursor: pointer;
        display: flex;
        gap: 1rem;
        align-items: center;
        span {
          font-size: 0.9rem;
          font-weight: 400;
          opacity: 0.8;
        }
        div {
          padding: 0;
        }
      }
      /* &:hover {
        .label {
          display: block !important;
        }
      } */
      
      .name {
        font-weight: 400;
        min-width: 10rem;
        a {
          text-decoration: none;
          color: black;
          &.hr-link {
            border: 1px solid black;
            border-radius: 2px;
            display: inline-block;
            margin-right: 0.5rem;
            padding: 1px 0 0 1px;
          }
        }
        .honest-resume {
          cursor: pointer;
          margin-left: 0.3rem;
          margin-bottom: -0.1rem;
        }
      }  
    }
    .loading-assessment {
      min-height: 7.25rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
    }
    .details {
      height: 0;
      overflow: hidden;
      width: 100%;
      /* background-color:  white; */
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      .filter-wrapper {
        flex: 1;
        margin: 1rem 0;
        font-size: 0.9rem;
        position: relative;
        .footer {
          margin-top: 2rem;
          .nice-to-haves {
            margin-top: 1rem;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
        > button {
            padding: 0 1rem;
            font-size: 0.8rem;
            height: 2.1rem;
            min-width: 7.5rem;
            align-self: flex-end;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        .header {
          /* display: flex;
          justify-content: space-between;
          margin-bottom: 0.5rem; */
          .contacts {
            font-weight: 400;
            display: flex;
            flex-direction: row;
            gap: 1.9rem;  
            /* margin-bottom: 0.5rem;  */
            .contact {
              display: flex;
              gap: 0.5rem;
              align-items: center;
              &.last-modified {
                font-size: 0.85rem;
                align-self: baseline;
                font-weight: 300;
              }
              svg {
                height: 1rem; 
                width: 1rem;
              }
            }     
            a {
              color: black;
              text-decoration: none;
              font-weight: 300;
              font-size: 0.8rem;
              line-height: 1.25rem;
            }
          }
        }
  
        .attributes {
          font-weight: 500;
          display: flex;
          flex-direction: column;
          gap: 0.2rem;  
          margin-top: 1.3rem;
          .filter {
            font-family: Roboto;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            text-align: left;
          }
          /* margin-bottom: 0.5rem;         */
        }
        .inference {
          display: flex;
          flex-flow: wrap;
          gap: 0.4rem;
          /* margin-top: 2.2rem; */
          margin-top: 1rem;
        }
        .filters {
          flex: 1;
          display: flex;
          flex-flow: wrap;
          gap: 2rem;
          >div {
            /* flex: 0.5; */
            div.filter {
              font-weight: 400;
              margin-right: 1rem;
              margin-bottom: 0.1rem;
              display: flex;
              font-size: 0.75rem;
              align-items: center;
              gap: 1rem;
            }
          }
         
        }
      }
    }

    &.red {
      background-color: var(--color-red-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-red-1);
        }
      }
    }
    &.grey {
      background-color: var(--color-gray-2);
    }
    &.green {
      background-color: var(--color-green-0);
      .banner {
        .filter_cnt {
          border: 1px solid var(--color-green-1);
        }
      }
    }
    &.expanded {
      border: 1px solid hsl(0, 0%, 80%);
      /* .banner .label {
        display: block;
      } */
      .details {
        height: auto;
        background-color: white;
        border-top: 1px solid hsl(0, 0%, 80%);
      }
    }
    
`

export const Pill = styled.div`
  font-size: 0.75rem;
  border-radius: 2px;
  padding: 0 0.75rem;
  line-height: 1.5rem;
  box-sizing: border-box;
  font-weight: 400;
  height: fit-content;
  width: fit-content;
  &.green {
    background-color: var(--color-green1);
    color: white;
    border: 1px solid var(--color-green1);
  }
  &.red {
    background-color: var(--color-red-1);
    color: white;
    border: 1px solid var(--color-red-1);
  }
  &.grey {
    background-color: white;
    border: 1px solid #000000;
  }
`

const ResumeAssesmentListItem = ({ data, askChatGPT, viewChatGPT, submitMatch, roleId }) => {
  const [ expanded, setExpanded ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const onAssessmentExpand = async () => {
    setExpanded(true);
    setIsLoading(true);
    try {
      const res = await getResumeAssessment(roleId, data.resume_id);
      data.assessment = {};
      data.assessment.filter_checks = res.filter_checks;
      data.assessment.inference = res.inference;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const renderLabel = () => {
    // return <PercentLoader view={PERCENT_LOADER_VIEW} percent={data.pct_complete || 0}/>
    if (data.status === 'Longer Processing') return  (
      <i>Requires data prep, ready in upto 2hrs</i>
    )
    if (data.status === 'Processing') return  (
      <PercentLoader view={PERCENT_LOADER_VIEW} percent={data.pct_complete || 0}/>
    )
    if (data.status === 'complete' && expanded) return (
      <div className="label">
          {renderCounter()}
          <div onClick={() => setExpanded(false)}><MdExpandLess size='1.5rem' style={{opacity: 0.5}}/></div>
      </div> 
    )
    if (data.status === 'complete' && !expanded) return (
      <div className="label">
         {renderCounter()}
        <div onClick={onAssessmentExpand}><MdExpandMore size='1.5rem' style={{opacity: 0.5}}/></div>
      </div>
    )
  }
  const getClassName = () => {
    const is_qualified = data.cnt_filters_qualified === data.cnt_filters && data.cnt_filters !== null && data.cnt_filters !== undefined;
    let className = data.status === 'complete' ? (is_qualified ? 'green' : 'red')  : 'grey';
    if (expanded) {
      className += ' expanded'
    }
    return className;
  }

  // const renderChatGPTButton = () => {
  //   if (!data.chatGPT) return <WhiteButton onClick={askChatGPT}>Ask ChatGPT</WhiteButton>;
  //   if (data.chatGPT === true) return <WhiteButton onClick={()=>{}}> Waiting for ChatGPT answer 
  //   <Spinner size="1rem" style={{height: '1rem', paddingLeft: '0.5rem'}}/></WhiteButton>;
  //   return <WhiteButton onClick={viewChatGPT}>ChatGPT Assessment</WhiteButton>;
  // }

  const onMatchesRoleClick = () => {
    submitMatch(data?.resume_id)
  }

  const renderMatchesButton = () => {
    if (!submitMatch) return null;
    return <WhiteButton onClick={onMatchesRoleClick}>Matches Role</WhiteButton>;
  }

  const renderFilters = () => {
    let filters = data?.assessment?.filter_checks;
    if (!filters) {
      filters = { ...data?.assessment };
      delete filters.inference;
      delete filters.attributes;
    }
    if (Object.keys(filters).length === 0) return null;
    let green_keys = Object.keys(filters).filter(key => filters[key]?.color === 'green');
    let red_keys = Object.keys(filters).filter(key => filters[key]?.color === 'red');
    let grey_keys = Object.keys(filters).filter(key => filters[key]?.color === 'grey');

    const formatValue = (key) => {
      if (key === 'atleast_one') {
        let value = filters[key].value;
        (filters[key]?.skills_present || []).forEach(c => { value = value.replace(c, `<b>${c}</b>`) });
        return <span dangerouslySetInnerHTML={{__html: value}}/>;
      }
      return filters[key].value
    }

    let nice_to_haves;

    if (filters?.nice_to_haves) {
      const { skills_present, skills_required } = filters?.nice_to_haves;
      if (skills_required && skills_present) {
        const formattedValue = skills_required.map((skill, i) => {
          let item = i < skills_required.length-1 ? skill+',' : skill;
          if (skills_present.includes(skill)) {
            item = `<span style="color:#00BB29;">${item}</span>`;
          }
          return item;
        }).join(' ');
        nice_to_haves = <div className='nice-to-haves'>Nice to have skills: <span dangerouslySetInnerHTML={{__html: formattedValue}}/></div>
      }
    }

    return <>
      <div className="filters">
        {
          green_keys.length > 0 &&
          <div>
            {
              green_keys.map((key, i) => (
                <div key = {i} className={'filter'}>
                <CheckmarkYes/> <span>{filters[key].label}:&nbsp;{formatValue(key)}</span>
                </div>
              ))
            }
          </div>
        }
        {
          red_keys.length > 0 &&
          <div>
            {
              red_keys.map((key, i) => (
                <div key = {i} className={'filter'}>
                  <CheckmarkNo/> <span>{filters[key].label}:&nbsp;{filters[key].value}</span>
                </div>
              ))
            }
          </div>
        }
        {
          grey_keys.length > 0 &&
          <div>
            {
              grey_keys.map((key, i) => (
                <div key = {i} className={'filter'}>
                  <FaMinus 
                    size={'1rem'} 
                    style={{color: 'grey'}}/> 
                    <span>{filters[key].label}:&nbsp;{filters[key].value}</span>
                </div>
              ))
            }
          </div>
        }
      </div> 
      {nice_to_haves}
    </>
  }

  const renderAttributes = () => {
    if (!data?.assessment?.attributes) return null;
    let keys = Object.keys(data.assessment.attributes);
    return <div className="attributes">
      {
        keys.map((key, i) => (
          <div key = {i} className={'filter grey'}>
            {key}: {data.assessment.attributes[key]}
          </div>
        ))
      }
    </div>
  }

  const renderInference = () => {
    if (!data?.assessment?.inference) return null;
    return <div className="inference">
      {
        data?.assessment?.inference.map((item, i) => (
          <Pill key = {i} className={item.color}>
            {item.label}
          </Pill>
         ))
      }
    </div> 
  }

  const renderCounter = () => {
    if (data.cnt_filters_qualified === null || data.cnt_filters_qualified === undefined)
      return null;
    return <div className="filter_cnt">{data.cnt_filters_qualified}/{data.cnt_filters}</div>
  }

  const renderDetails = () => {
    if (data.status !== 'complete') return null;
    if (isLoading) return (
      <div className="loading-assessment">
        <Spinner size="2rem"/>
      </div>
    )

    return <div className="details">
      <div className="filter-wrapper">
        <div className="header">
          {
            (data.email || data.phone_number || data.linkedin_url || data.as_of_dt) &&
            <div className="contacts">
              {
                data.email &&
                <div className="contact">
                  <MailIcon/>
                  <a href={`mailto: ${data.email}`}>{data.email}</a>
                </div>
              }
              {
                data.phone_number &&
                <div className="contact">
                  <PhoneIcon/>
                  <a href={`tel: ${data.phone_number}`}>{data.phone_number}</a>
                </div>
              }
              {
                data.linkedin_url && 
                <div className="contact">
                  <a 
                    href={data.linkedin_url}
                    target='__blank'>
                  <LinkedinIcon/>
                  </a>
                </div>  
              }
              {
                data.assessment?.google_search_url && 
                !data.linkedin_url &&
                <div className="contact">
                  <a 
                    href={data.assessment.google_search_url}
                    style={{lineHeight: 'normal'}}
                    target='__blank'>
                  <GoogleIcon style={{width: '1.5rem', height: '1.5rem', marginBottom: '-0.1rem'}}/>
                  </a>
                </div>  
              }
              {
                data.as_of_dt &&
                <>
                  <div style={{flex: 1}}></div>
                  <div 
                    className="contact last-modified"
                    data-tooltip-id="my-tooltip-rali"
                    data-tooltip-content=""
                    data-tooltip-place="top"
                  >
                    Last modified: {data.as_of_dt}
                  </div>
                </>
              }
            </div>
          }
          {renderAttributes()}
          {renderInference()}
        </div> 
        <div className="footer">
          {renderFilters()} 
        </div>
        {/* {renderChatGPTButton()} */}
        {renderMatchesButton()}
      </div>
    </div>
  }
  
  return (
    <Container className={getClassName()}>
      <div className="banner">
        <div className="name">
        {
          data.status === 'complete' && 
          <a 
            className="hr-link"
            href={`/honest-resume?resume_id=${data.resume_id}&role_id=${roleId}`} 
            rel="noreferrer" 
            target="_blank">
              <img 
                src={HR}
                alt={"Honest Resume"}
                style={{
                  margin: "0 0.2rem",
                  height: "0.8rem"
                }}
              />
          </a>
          }
          <a href={data.topnerd_s3_url} rel="noreferrer" target="_blank">
            {data.full_name || data.resume_file_name}     
          </a> 
          
        </div>
        {renderLabel()}
      </div>
      {renderDetails()} 
      <Tooltip id="my-tooltip-rali">
          <div style={{width: '16rem'}}>This is the last modified date we read from the resume file. This date impacts 
            how employment(s) with To date as “present/current/etc” are handled.</div>
      </Tooltip>
    </Container>
  );
}

export default ResumeAssesmentListItem;
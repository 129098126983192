import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-tabs/style/react-tabs.css';
import { AiFillLinkedin, AiFillGithub } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import { GrEdit } from "react-icons/gr";
import { MdOutlineCancel } from "react-icons/md";
import { MdOutlineCheckCircle } from "react-icons/md";

const Container = styled.div`
  margin: 2rem auto;
  height: 100%;
  max-width: 60rem;
  font-weight: 400;
  font-family: Roboto;
  .editable-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    .edit-icon {
      cursor: pointer;
      width: 0.8rem;
      margin-left: 0.5rem;
    }
    .save-icon {
      margin-left: 0.5rem;
    }
    .save-icon, .cancel-icon {
      cursor: pointer;
      width: 1.2rem;
    }
    input {
      padding: 0.2rem 0.5rem;
      font-size: 1rem;
    }
  }
  
  .no_usa_edu_exp{
    background: rgb(167, 33, 22);
    width: fit-content;
    color: white;
    margin-top: 0.5rem;
    border-radius: 3px;
    padding: 0.4rem 0.5rem;
    font-size: 0.8rem;
  }


  .resume-records {
    display: flex;
    >div {
      flex: 0.5;
      &.primary-records {
        label {
          min-width: 13rem;
          display: inline-block;
        }
      }
      &.secondary-records {
        label {
          min-width: 10rem;
          display: inline-block;
        }
      }
    }
    .record {
      margin-bottom: 0.3rem;
      font-size: 1rem;
      a {
        color: black !important;
      }
      label {
        font-weight: 500;
        margin-right: 0.2rem;
        &.aligned {
          min-width: 13rem;
          display: inline-block;
        }
      }
    }
  }
  .employment-record {
      position: relative;
      .employment-record-company {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        .location {
          font-size: 1rem;
        }
      }
      .employment-record-title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
      }
      .employment-record-from-to {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
      }
      .employment-record-description {
        font-size: 1rem;
        margin-bottom: 1rem;
      }
    }
  .work-record {
    margin-top: 2rem;
    .work-record-header {
      display: flex;
      .company-name {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      .title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .domain {
        margin-left: 0.3rem;
      }
      .spacer {
        flex: 1;
      }
      .tenure {
        color: var(--color-blue1old);
        font-weight: 600;
      }
    }
    .description {
      margin-top: 0.3rem;
      font-size: 0.9rem;
    }
  }
  .latest-jobs {
    margin: 1.5rem auto 0;
    /* width: fit-content; */
    .skills {
      font-size: 1.2rem;
      margin-top: 0.5rem;
    }
    h3 {
      font-weight: 600;
      margin: 0 0 1.5rem;
      text-align: center;
      font-size: 1.5rem;
    }
    table {
      border-collapse: collapse;
      border: 1px solid var(--color-gray2);
      tr, th, td {
        border: 1px solid var(--color-gray2);
        &.hidden {
          display: none;
        }
      }
      th, td {
        padding: 0.5rem;
        box-sizing: border-box;
        &.expand-button {
          width: 2rem;
          text-align: center;
          cursor: pointer;
        }
      }
      th {
        text-align: center;
        font-weight: 600;
        background-color: var(--color-gray0)
      }
      margin-top: 1rem;
    }
  }

  .education-record {
    position: relative;
    .education-record-institution {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
    }
    .education-record-degree {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
    .education-record-graduation-date {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  .school-record {
    margin-top: 2rem;
    .spacer {
      flex: 1;
    }
    text-transform: capitalize;
    .school-record-header {
      display: flex;
      text-transform: capitalize;
      .school-name {
        font-weight: 600;
      }
      .graduate-year {

      }
    }
    .school-record-footer {
      margin-top: 0.2rem;
      display: flex;
    }
  }
  @media screen and (max-width: 40rem) {
    .resume-records {
      flex-direction: column;
    }
    .badges {
      div {
        div {
          font-size: 0.7rem;
          padding: 0.5rem;
          width: 10rem;
          min-width: 10rem;
        }
      }
    }
  }
`

const Pill = styled.div`
  margin: 0.1rem 0 0 0.5rem;
  font-size: 0.7rem;
  border-radius: 1rem;
  padding: 0.3rem 0.5rem;
  box-sizing: border-box;
  font-weight: 600;
  height: fit-content;
  width: fit-content;
  &.green {
    background-color: var(--color-green1);
    color: white;
  }
  &.blue {
    background-color: var(--color-blue1old);
    color: white;
  }
  &.red {
    background-color: rgb(234, 153, 153);
    color: white;
  }
  &.grey {
    background-color: var(--color-gray0);
  }
  &.orange {
    color: white;
    background-color: rgb(203,109,46);
  }
  &.white {
    color: black;
    background-color: white;
    border: 1px solid black;
    font-weight: 500;
  }
`
const Gap = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray0);
  border-radius: 0.3rem;
  font-weight: 600;
  width: 100%;
`
const getAcceptanceAssessment = (assessment) => {
  if (assessment === 'top school' || assessment === 'good school') return (
    <Pill className='green'>{assessment}</Pill>
  );
  if (assessment) {
    return <Pill className='white'>{assessment}</Pill>
  } 
  return null;
}

const renderSchoolRecord = (record, i) => (
  <div className='education-record' key={i}>
    <div className='education-record-institution'>
      {record.institution_name}
      {getAcceptanceAssessment(record.acceptance_assessment)}
      {
        record.country &&
        <>
          <div style={{flex: 1}}/>
          <Pill className='white'>{record.country}</Pill>
        </>
      }
    </div>
    <div className='education-record-degree'>{record.degree}, {record.major}</div>
    <div className='education-record-graduation-date'>{record.graduation_dt}</div>
  </div>
)

const renderWorkRecord = (role, i) => {
  if (role.gap_in_months) {
    return <Gap style={{height: role.gap_in_months < 12 ? '3rem' : '4rem'}} key={i}>
        {role.length_label}
    </Gap>
  }
  return (
    <div className='employment-record' key={i}>
    {
      role.company_name !== 'AT PREV EMPLOYER' &&
      <div className='employment-record-company'>
        {role.company_name}
        {
          role.company_type &&
          role.company_type !== 'while in school' &&
          <Pill className='blue'>{role.company_type}</Pill>
        }
        {
          role.se_role_type &&
          <Pill className='blue'>{role.se_role_type}</Pill>
        }
        {
          role.location_text &&
          <>
            <div style={{flex: 1}}/>
            <div className="location">{role.location_text}</div>
          </>
          
        }
        
      </div>
    }  
   
    <div className='employment-record-title'>
      {role.title}
      {
        role.was_promoted &&
        <Pill className='green'>promoted</Pill>
      }
      {
        role.is_se_relevant === false &&
        <Pill className='red'>not software relevant</Pill>
      }
      <div style={{flex:1}}/>
      {
        role.company_type === 'while in school' && 
        <Pill className='orange'>{role.company_type}</Pill>
      }
    </div>
    {
      role.from_dt && 
      role.to_dt &&
      <div className='employment-record-from-to'>
        {role.from_dt} - {role.to_dt} 
        {
          role.duration &&
          <Pill 
            className='white'
            style={{
              display: 'block',
              marginTop: '-0.05rem'
            }}
          >{role.duration}</Pill>
        }
      </div>
    }
    
    <div className='employment-record-description'>{role.description}</div>
  </div>
  )
}

const ParsedResume = ({ data, saveNameHr }) => {
  const [name, setName] = useState(data?.name?.trim());
  const [nameEditing, setNameEditing ] = useState(false);

  const editName = () => {
    setNameEditing(true);
  }

  const cancelName = () => {
    setName(data?.name?.trim());
    setNameEditing(false);
  }

  const saveName = async () => {
    setNameEditing(false);
    const oldName = name;
    try {
      data.name = name;
      await saveNameHr(name);
    } catch (err) {
      setName(oldName);
      data.name = oldName;
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
  }
  
  const renderName = () => {
    if (nameEditing) return (
      <span className="editable-name">
        <input value={name} onChange={onNameChange} autoFocus/>
        <MdOutlineCheckCircle onClick={saveName} className="save-icon"/>
        <MdOutlineCancel onClick={cancelName} className="cancel-icon"/>
      </span>
    )
    return (
      <span className="editable-name">
        {data?.name}  <GrEdit className="edit-icon" onClick={editName}/>
      </span>
    )
  }


  return <Container>
    {
      data &&
      <>
      <div className="resume-records" style={{alignItems: 'center', display: 'flex', flex:1, flexDirection: 'column'}}>
        <div className='record'>{renderName()}</div>
        {
          data?.location_text &&
          <div className='record' style={{fontSize: '1rem'}}>
            {data?.location_text}
          </div>
        }
        {
          data?.email &&
          <div className='record' style={{fontSize: '1rem'}}>
            <span style={{paddingRight: '0.2rem'}}>E:</span>
            <a 
              target='_blank' 
              rel='noreferrer' 
              href={`mailto: ${data?.email}`}>
                {data?.email}
            </a>
          </div>
        }
        {
          data?.phone &&
          <div className='record' style={{fontSize: '1rem'}}>
            <span style={{paddingRight: '0.2rem'}}>T:</span> 
            <a 
              target='_blank' 
              rel='noreferrer' 
              href={`tel: ${data?.phone}`}>
                {data?.phone}
            </a>
          </div>
        }
        {
          (data?.linkedin || data?.github) &&
          <div className='record' style={{fontSize: '1rem'}}>
            {
                data?.linkedin &&
                <a 
                target='_blank' 
                rel='noreferrer' 
                href={data?.linkedin}>
                    <AiFillLinkedin size={'1.2rem'}/>
              </a>
            }
            {
                data?.github &&
                <a 
                target='_blank' 
                rel='noreferrer' 
                href={data?.github}>
                  <AiFillGithub  size={'1.2rem'}/>
              </a>
            }
          </div>
        }
      </div> 
      <br/>
      <br/>  
      <div className="resume-records">
        <div className="primary-records">
            {/* <div className='record'><label>Country of Residence:</label> {data?.country_of_residence}</div> */}
            <div className='record'><label className='aligned'>Total YoE (after school): </label> {data?.yoe?.total}</div>
            {
              data?.yoe?.ic_yoe &&
              <div className='record'><label className='aligned'>IC YoE: </label> {data?.yoe?.ic_yoe}</div>
            }            
            {
              data?.yoe?.devops_yoe &&
              <div className='record'><label className='aligned'>DevOps YoE: </label> {data?.yoe?.devops_yoe}</div>
            }
            {
              data?.yoe?.ml_yoe &&
              <div className='record'><label className='aligned'>MLE YoE: </label> {data?.yoe?.ml_yoe}</div>
            }
            {
               data?.yoe?.manager_yoe &&
              <div className='record'><label className='aligned'>Manager YoE:</label> {data?.yoe?.manager_yoe}</div>
            }           
            <br/>
          </div>
          <div className="secondary-records">
            <div className='record'><label>Immigration Status:</label> {data?.immigration}</div>
            {
              data?.stack_focus &&
              <div className='record'><label> Stack Focus:</label> {data?.stack_focus}</div>
            }
            {
              data?.no_usa_edu_exp &&
              <div className='no_usa_edu_exp'> no usa education\experience </div>
            }                   
            {
              data?.["jobs-changed-often"] &&
              <div className='no_usa_edu_exp'> jobs changed often </div>
            }
          </div>
        </div>
        {
          !!data?.work_experience?.length && <>
            <div className='latest-jobs'>
              <h3>Work Experience</h3>
                {
                  data?.work_experience?.map((role, i) => renderWorkRecord(role, i))
                }
            </div>
            <br/>
          </>
        }
        {
          !!data?.education?.length && <>
            <div className='latest-jobs'>
            <h3>Education</h3>
              {
                data?.education?.map((role, i) => renderSchoolRecord(role, i))
              }
            </div>
          </>
        }
        {
          !!data?.skills?.length && <>
            <div className='latest-jobs'>
              <h3>Skills </h3>
              <div className="skills">{data?.skills?.join(', ')}</div>
            </div>
          </>
        }
        <Tooltip id="tooltip" style={{background: '#555'}}/>
      </>
    }
  </Container>
}

export default ParsedResume;